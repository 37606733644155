import {createSelector} from "reselect";
import {createBooleanFieldSelector, createFieldSelector, identityFunction} from "../utils/selectors";
import {MONTH_VIEW, MULTIPLE_ARTIST_VIEW, SINGLE_ARTIST_VIEW} from "./CalendarReducer";
import {zeroPad} from "../utils/period";
import {evaluateFormularstatus} from "../../models/formularstatus";

export const isMultipleArtistView = viewType => viewType === MULTIPLE_ARTIST_VIEW
export const isSingleArtistView = viewType => viewType === SINGLE_ARTIST_VIEW
export const isMonthView = viewType => viewType === MONTH_VIEW

export const selectCalendarState = createSelector(
    (state) => state.calendar
    , identityFunction
)

export const selectCalendarEvents = createFieldSelector(selectCalendarState, 'events')
export const selectCalendarSelectedSearchedKunden = createFieldSelector(selectCalendarState, 'selectedSearchedKunde')
export const selectCalendarSearchedKunden = createFieldSelector(selectCalendarState, 'searchedKunden')
export const selectIsSearchedKundenResultAvailable = createBooleanFieldSelector(selectCalendarState, 'searchedKunden')
export const selectCalendarKundenMap = createFieldSelector(selectCalendarState, 'kunden')
export const selectViewConfigurationType = createFieldSelector(selectCalendarState, 'viewType')
export const selectSelectedTerminIDCalendar = createFieldSelector(selectCalendarState, 'selectedTerminID')
export const selectSelectedLocationKeysCalendar = createFieldSelector(selectCalendarState, 'selectedLocationKeys')
export const selectSelectedKundIDCalendar = createFieldSelector(selectCalendarState, 'selectedKundID')
export const selectSelectedKundeCalendar = createSelector(
    selectCalendarKundenMap
    , selectSelectedKundIDCalendar
    , (kundenMap, selectedKundID) => kundenMap && selectedKundID && kundenMap[selectedKundID]
)
export const selectCalendarSlotDuration = createSelector(
    selectCalendarState
    , state => '00:' + zeroPad(state.slotDuration || 30, 2)
)
export const selectCalendarEventParams = createFieldSelector(selectCalendarState, 'eventsParams')
export const selectCalendarEventZeitraum = createFieldSelector(selectCalendarEventParams, 'zeitraum')
export const selectTermine = createFieldSelector(selectCalendarEvents, 'termine')

export const selectLoadedZeitraumOfEvents = createFieldSelector(selectCalendarEvents, 'loadedZeitraum')
export const selectHolidays = createFieldSelector(selectCalendarEvents, 'holidays', [])
export const selectIsTermineLoading = createBooleanFieldSelector(selectCalendarState, 'eventsLoading')
export const selectSelectedCalendarArtistID = createFieldSelector(selectCalendarState, 'calendarArtist')
export const selectCalendarKundenTermine = createFieldSelector(selectCalendarState, 'selectedKundenTermine')
export const selectCalendarSelectedTerminToaster = createFieldSelector(selectCalendarState, 'selectedTerminToaster')
export const selectCalendarSelectedTerminToasterImages = createFieldSelector(selectCalendarState, 'toasterImages')
export const isSelectCalendarSelectedTerminToasterImagesLoading = createBooleanFieldSelector(selectCalendarState, 'toasterImagesLoading')
export const selectCalendarSelectedTerminToasterProjekt = createFieldSelector(selectCalendarSelectedTerminToaster, 'projekt')
export const selectCalendarSelectedTerminToasterAnamneseFile = createFieldSelector(selectCalendarSelectedTerminToaster, 'anamnese')
export const selectCalendarSelectedTerminToasterTermin = createFieldSelector(selectCalendarSelectedTerminToaster, 'termin')
export const selectCalendarSelectedTerminToasterFormularStatus = createSelector(
    selectCalendarSelectedTerminToaster
    , t => t && t.formularStatus && evaluateFormularstatus(t.formularStatus)
)

export const selectSearchTimeslotLoading = createBooleanFieldSelector(selectCalendarState, 'searchTimeslotsLoading')
export const selectFoundTimeslot = createFieldSelector(selectCalendarState, 'timeslots')

