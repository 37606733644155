import {useEffect, useRef} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import { BACKEND } from "../config/backend";
import axios from "axios";

export const APP_VERSION = process.env.REACT_APP_VERSION;

const getBackendVersion = async (signal) => {
    const response = await axios.get(`${BACKEND}/api/v1/system/app-compatibility`, {
        signal
    });
    return response.data;
};

const isBackendNewer = (frontendVersion, backendVersion) => {
    const frontendParts = frontendVersion.split('.').map(Number);
    const backendParts = backendVersion.split('.').map(Number);
    
    for (let i = 0; i < 2; i++) {
        if (backendParts[i] > frontendParts[i]) return true;
        if (backendParts[i] < frontendParts[i]) return false;
    }
    return false;
};

export const useBackendVersionSync = () => {
    const queryClient = useQueryClient();
    const lastCheckedVersion = useRef(null);
    const reloadScheduled = useRef(false);
    
    const { data: backendVersionMap } = useQuery({
        queryKey: ['system', 'backend-version'],
        queryFn: ({ signal }) => getBackendVersion(signal),
        staleTime: 1000 * 60 * 5, // 5 Minuten Cache
        gcTime: 1000 * 60 * 10,   // 10 Minuten Garbage Collection
    });

    useEffect(() => {
        let abortController = new AbortController();

        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible' && !reloadScheduled.current) {
                abortController.abort();
                abortController = new AbortController();

                try {
                    await queryClient.refetchQueries({
                        queryKey: ['system', 'backend-version'],
                        signal: abortController.signal,
                    });

                    if (backendVersionMap?.main && 
                        backendVersionMap.main !== lastCheckedVersion.current && 
                        isBackendNewer(APP_VERSION, backendVersionMap.main)) {
                        
                        lastCheckedVersion.current = backendVersionMap.main;
                        reloadScheduled.current = true;
                        console.log('Backend ist neuer, Seite wird neu geladen');
                        window.location.reload();
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        console.error('Versionsprüfung fehlgeschlagen:', error);
                    }
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            abortController.abort();
        };
    }, [queryClient, backendVersionMap]);
};