import { CHANGE_VIEW_TYPE, changeCalendarSelectedLocationAction, initiateLoadCalendarAction } from "../calendarActions";
import { SINGLE_ARTIST_VIEW } from "../CalendarReducer";
import { isMonthView, selectCalendarEventZeitraum } from "../calendarSelector";
import { changeZeitraumForResourceView, getMonthFromDate, getWeekFromDate } from "../../utils/period";
import { put, select, takeLatest } from "redux-saga/effects";
import { PlainDate } from "@kiss-solutions/plaindate";
import { selectSelectedTatorIdOfResourceFilter } from "../calendarResourceSelector";
import { selectLoggedInArtist } from "../../auth/authSelector";

function* adaptSelectedZeitraumForChangedViewType({ payload }) {
    const isSingle = payload.viewType === SINGLE_ARTIST_VIEW;
    if (!isSingle) {
        const selectedTatort = yield select((state) => selectSelectedTatorIdOfResourceFilter(state));
        if (!selectedTatort) {
            const loggedInArtist = yield select((state) => selectLoggedInArtist(state));
            yield put(changeCalendarSelectedLocationAction(loggedInArtist.StdOrtID));
        }
    }
    let zeitraum = yield select((state) => selectCalendarEventZeitraum(state));
    zeitraum = isSingle
        ? getWeekFromDate(new PlainDate(zeitraum.start))
        : isMonthView(payload.viewType)
        ? getMonthFromDate(new PlainDate(zeitraum.start))
        : changeZeitraumForResourceView(zeitraum);
    yield put(initiateLoadCalendarAction(zeitraum));
}

export function* changeViewTypeSaga() {
    yield takeLatest(CHANGE_VIEW_TYPE, adaptSelectedZeitraumForChangedViewType);
}
