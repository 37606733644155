import {PROJEKT_BASE_URL} from "../api/projektApi";
import * as yup from "yup";
import {transformNoNumberToUndefined} from "../store/utils/yupSchema";
import {hatRecht, RECHT_SCHREIBEN} from "./auth";
import { v4 as uuid } from 'uuid'
import {getArtistsStdPreisFor} from "./artist";
import {isValidDate} from "../store/utils/dateHelper";
import {convertStringToNumber} from "../store/utils/numericHelper";
import {i18_TRANSLATION} from "../i18nReferences";

export const PROJEKTSTATUS_OFFEN = 0
export const PROJEKTSTATUS_RUHEND = 1
export const PROJEKTSTATUS_GESCHLOSSEN = 2
export const PROJEKTSTATUS_WARTEND = 3
export const PROJEKTSTATUS_OFFEN_OR_RUHEND = [PROJEKTSTATUS_OFFEN, PROJEKTSTATUS_RUHEND]
export const PROJEKTSTATUS_OFFEN_RUHEND_OR_WARTEND = [PROJEKTSTATUS_OFFEN, PROJEKTSTATUS_RUHEND, PROJEKTSTATUS_WARTEND]
export const PROEJKTSTATI_MAP = {
    PROJEKTSTATUS_OFFEN, PROJEKTSTATUS_RUHEND, PROJEKTSTATUS_GESCHLOSSEN, PROJEKTSTATUS_WARTEND
}
export const PROEJKTSTATI_TS_MAP = Object.keys(PROEJKTSTATI_MAP).reduce((result, key) => ({
    ...result,
    [PROEJKTSTATI_MAP[key]]: key
}), {})

export const convertImageKeyToUrl = (key) => {
    const encodedKey = encodeURIComponent(key)
    return `${PROJEKT_BASE_URL}/image?key=${encodedKey}`
}

export const convertProjekt2SelectionTitleWithArtist = (projekt, translatedProjektstatusMap, artistMap) => `${artistMap[projekt.ArtistID].Kuerzel}: ${projekt.ProjName} (${translatedProjektstatusMap[projekt.ProjStat]})`

export const convertProjekt2SelectionWithArtist = (projekt, translatedProjektstatusMap, artistMap) => ({
    id: projekt.ProjektID,
    text: `${artistMap[projekt.ArtistID].Kuerzel}: ${projekt.ProjName} (${translatedProjektstatusMap[projekt.ProjStat]})`,
    value: projekt
})

export const PROJEKT_ERROR_FIELDS = ['ProjName', 'AnzAgreed', 'AnzStatus', 'AnzInfo', 'Ausstehend', 'LetzTerm', 'LetzArbTerm', 'Preis', 'WarteVon', 'WarteBis']

export const convertProjekt2Schema = projekt => {
    return {
        ...projekt
        , ArtistID: undefined
        , Bemerkung: undefined
        , projektArtist: projekt.ArtistID
        , projektBemerkung: projekt.Bemerkung
        , LetzTerm: projekt.LetzTerm && new Date(projekt.LetzTerm)
        , LetzArbTerm: projekt.LetzArbTerm && new Date(projekt.LetzArbTerm)
        , WarteVon: projekt.WarteVon && new Date(projekt.WarteVon) || undefined
        , WarteBis: projekt.WarteBis && new Date(projekt.WarteBis) || undefined
    }
}

export const convertWarteFields2Schema = (warteFields) => Object.keys(warteFields).reduce((pv, key) => {
    const dateString = warteFields[key]
    pv[key] = dateString ? new Date(dateString) : null
    return pv
}, {})

export const convertV2Projekt2Schema = projekt => {
    return {
        ...projekt
        , ArtistID: undefined
        , Bemerkung: undefined
        , projektArtist: projekt.ArtistID
        , projektBemerkung: projekt.Bemerkung || ''
        , ProjStat: projekt.ProjStat
        , ProjName: projekt.ProjName
        , Preis: projekt.Preis || ''
        , AnzAgreed: projekt.AnzAgreed || '0'
        , AnzStatus: projekt.AnzStatus || '0'
        , AnzInfo: projekt.AnzInfo || ''
        , Offen: projekt.Offen || 0
        , Ausstehend: projekt.Ausstehend || 0
        , Erledigt: projekt.Erledigt || 0
        , LetzTerm: projekt.LetzTerm && new Date(projekt.LetzTerm) || undefined
        , LetzArbTerm: projekt.LetzArbTerm && new Date(projekt.LetzArbTerm) || undefined
        , WarteVon: projekt.WarteVon && new Date(projekt.WarteVon) || undefined
        , WarteBis: projekt.WarteBis && new Date(projekt.WarteBis) || undefined
    }
}

export const mergeFormDataWithProjekt = (formData, projekt, loggedInArtistId) => projekt && formData && ({
    ...projekt
    , ArtistID: formData.projektArtist
    , Bemerkung: formData.projektBemerkung
    , ProjStat: formData.ProjStat
    , ProjName: formData.ProjName
    , AnzAgreed: formData.AnzAgreed && +formData.AnzAgreed || 0
    , AnzStatus: formData.AnzStatus && +formData.AnzStatus || 0
    , AnzInfo: formData.AnzInfo
    , AnzTerm: +formData.Ausstehend + projekt.Offen + projekt.Erledigt
    , Ausstehend: formData.Ausstehend && +formData.Ausstehend
    , WarteVon: formData.WarteVon && isValidDate(formData.WarteVon) && formData.WarteVon.toISOString() || null
    , WarteBis: formData.WarteBis && isValidDate(formData.WarteBis) && formData.WarteBis.toISOString() || null
    , LetzTerm: formData.LetzTerm && formData.LetzTerm.toISOString() || undefined
    , LetzArbTerm: formData.LetzArbTerm && formData.LetzArbTerm.toISOString() || undefined

    , Preis: formData.Preis
    , _createdID: projekt._createdID || loggedInArtistId
    , _createdTS: projekt._createdTS || new Date().toISOString()
})

export const convertSchemaChanges2Projekt = (formData) => ({
    ...formData
    , ArtistID: formData.projektArtist
    , Bemerkung: formData.projektBemerkung
    , AnzAgreed: formData.AnzAgreed && +formData.AnzAgreed || 0
    , AnzStatus: formData.AnzStatus && +formData.AnzStatus || 0
    , WarteVon: formData.WarteVon && isValidDate(formData.WarteVon) && formData.WarteVon.toISOString() || null
    , WarteBis: formData.WarteBis && isValidDate(formData.WarteBis) && formData.WarteBis.toISOString() || null
    , AnzTerm: +formData.Ausstehend + formData.Offen + formData.Erledigt
})


const getValidationForAnzStatus = ([AnzAgreed, ProjStat, ProjektID], schema) => {
    if (ProjStat === PROJEKTSTATUS_OFFEN && isGespeichertesProjekt(ProjektID) && AnzAgreed > 0 && ProjStat === PROJEKTSTATUS_OFFEN) {
        return schema.min(AnzAgreed, {key: 'YUP.WARNING.ANZSSTATUS_NOT_FULL'}).required({key: 'YUP.WARNING.ANZSSTATUS_NOT_FULL'})
            .max(AnzAgreed, ({max}) => ({key: 'YUP.WARNING.LESS_THAN', values: {max}}))
    }
    if (ProjStat === PROJEKTSTATUS_GESCHLOSSEN) {
        return schema.max(0, {key: 'YUP.WARNING.ANZSSTATUS_SHOULD_BE_EMPTY'}).nullable(true)
    }
    return schema.optional().max(AnzAgreed, ({max}) => ({key: 'YUP.WARNING.LESS_THAN', values: {max}}))
}

const getValidationForAusstehend = ([ProjStat, Offen, Erledigt, AnzTerm], schema) => {
    if (ProjStat === PROJEKTSTATUS_OFFEN && !AnzTerm) {
        const summe = (Offen || 1) + (Erledigt || 0)
        return schema.required({key: 'YUP.WARNING.AUSSTEHEND_FEHLT'}).min(summe, {key: 'YUP.WARNING.AUSSTEHEND_FEHLT'})
    }
    if (ProjStat !== PROJEKTSTATUS_GESCHLOSSEN) {
        return schema.min(0, {key: 'YUP.WARNING.AUSSTEHEND_NEGATIV'})
    }
    return schema.optional()
}

const projektArtistValidationWhen = yup.number().when(['$artist'], ([artist], schema) => artist ? schema.oneOf([artist, null], {key: 'YUP.WARNING.ARTISTS_DIFFER'}) : schema.required())

export const createProjektPartSchema = () => {
    return yup.object().shape({
        projektArtist: projektArtistValidationWhen,
        ProjName: yup.string().max(30).required(),
        AnzAgreed: yup.number().transform(transformNoNumberToUndefined).min(0).nullable(true),
        AnzInfo: yup.string().max(30).when(['AnzAgreed'], ([AnzAgreed], schema) => !AnzAgreed ? schema.required({key: 'YUP.WARNING.NEED_ANZINFO'}) : schema.nullable(true)),
        AnzStatus: yup.number().transform(transformNoNumberToUndefined).min(0)
            .when(['AnzAgreed', 'ProjStat', 'ProjektID'], getValidationForAnzStatus),
        Ausstehend: yup.number().transform(transformNoNumberToUndefined).when(['ProjStat', 'Offen', 'Erledigt', 'AnzTerm'], getValidationForAusstehend),
        Preis: yup.string().max(100).when(['ProjStat'], ([ProjStat], schema) => PROJEKTSTATUS_OFFEN_OR_RUHEND.includes(ProjStat) ? schema.required({key: 'YUP.WARNING.NEED'}) : schema.nullable(true)),
        LetzTerm: yup.date().when(['ProjStat', 'ProjektID'],
            ([ProjStat, ProjektID], schema) => ProjStat === PROJEKTSTATUS_OFFEN && ProjektID > 0 ? schema.required({key: 'YUP.WARNING.NO_APPOINTMENTS'}).min(new Date(), {key: 'YUP.WARNING.NEED_APPOINTMENTS'}) : schema.optional()),
        LetzArbTerm: yup.date().when(['ProjStat'],
            ([ProjStat], schema) => ProjStat === PROJEKTSTATUS_GESCHLOSSEN ?
                schema.max(new Date(), () => ({key: 'YUP.WARNING.CLOSED_PROJEKT_WITH_FUTURE_APPOINTMENTS'})) : schema.optional()),
        WarteBis: yup.date().nullable(),
        WarteVon: yup.date().when(['WarteBis', 'ProjStat'], createWarteVonSchema)
    })
}

const createWarteVonSchema = ([WarteBis, ProjStat], schema) => {
    if (WarteBis) {
        return schema.required().max(WarteBis, {key: 'YUP.ERROR.START_AFTER_END'})
    }
    if (ProjStat === PROJEKTSTATUS_WARTEND) {
        return schema.required()
    }
    schema.nullable(true)
}

export const createProjekt = ({ProjName, artist, KundID, _createdID, anzInfoText}) => ({
    ProjektID: -1,
    ProjName,
    ArtistID: artist?.ArtistID,
    ProjStat: PROJEKTSTATUS_OFFEN,
    KundID,
    AnzAgreed: artist?.Anzahlung,
    AnzStatus: 0,
    AnzInfo: !artist?.Anzahlung ? anzInfoText : undefined,
    AttFolder: uuid(),
    AnzTerm: 1,
    Ausstehend: 0,
    Preis: getArtistsStdPreisFor(artist, 1),
    Offen: 1,
    Erledigt: 0,
    _createdID,
    _createdTS: new Date().toISOString()
})


export const validateProjekt = (projekt, compareDate = new Date()) => {
    const errors = {}
    const anzAgreed = projekt.AnzAgreed && +projekt.AnzAgreed
    const anzStatus = projekt.AnzStatus && +projekt.AnzStatus
    if (anzAgreed < 0 || !anzAgreed && !projekt.AnzInfo) {
        errors.AnzAgreed = 1
    }
    if (projekt.WarteBis === undefined
        && projekt.ProjStat !== PROJEKTSTATUS_WARTEND
        && projekt.WarteVon !== undefined) {
        errors.WarteBis = 1
    }
    if (projekt.WarteVon === undefined
        && (projekt.ProjStat === PROJEKTSTATUS_WARTEND || projekt.WarteBis !== undefined)) {
        errors.WarteVon = 1
    }
    if (anzAgreed && anzAgreed > anzStatus && projekt.ProjStat === PROJEKTSTATUS_OFFEN
        || anzStatus && projekt.ProjStat === PROJEKTSTATUS_GESCHLOSSEN) {
        errors.AnzStatus = 1
    }
    if ((!projekt.Preis || projekt.Preis.length < 1)
        && ![PROJEKTSTATUS_WARTEND, PROJEKTSTATUS_GESCHLOSSEN].includes(projekt.ProjStat)) {
        errors.Preis = 1
    }
    if (projekt.Ausstehend < 0 && projekt.ProjStat !== PROJEKTSTATUS_GESCHLOSSEN) {
        errors.Ausstehend = 1
    }
    if (projekt.ProjStat === PROJEKTSTATUS_GESCHLOSSEN && new Date(projekt.LetzTerm).getTime() > compareDate.getTime()) {
        errors.LetzTerm = 1
    }
    if (projekt.gesperrt) {
        errors.gesperrt = 1
    }
    if (projekt.hatAnzDifferenz) {
        errors.hatAnzDifferenz = 1
    }
    return errors
}

export const isGespeichertesProjekt = projektId => projektId > 0

export const checkForWarteDateChanges = (projekt, projektStatus, WarteVon) => {
    if (projektStatus === PROJEKTSTATUS_WARTEND
        && !projekt.WarteVon) {
        return {WarteVon: new Date().toISOString()}
    }
    if (isGespeichertesProjekt(projekt.ProjektID)
        && (projekt.ProjStat === PROJEKTSTATUS_WARTEND || WarteVon)
        && projektStatus !== PROJEKTSTATUS_WARTEND
        && !projekt.WarteBis) {
        return {WarteBis: new Date().toISOString()}
    } else if (!isGespeichertesProjekt(projekt.ProjektID)
        && projektStatus !== PROJEKTSTATUS_WARTEND) {
        return {
            WarteBis: undefined,
            WarteVon: undefined
        }
    }
    return {}
}

export const fehltAnzahlungKomplett = projekt => projekt && convertStringToNumber(projekt.AnzStatus, 0) <= 0 && convertStringToNumber(projekt.AnzAgreed, 0) > 0 || false

export const getLetzterTermin = projekt => projekt.ProjStat === PROJEKTSTATUS_GESCHLOSSEN ? projekt.LetzTerm : projekt.LetzArbTerm

export const canArtistEditProjekt = (selectedArtistId, loggedInArtist, rechteMap) => {
    if (loggedInArtist?.isAdmin) {
        return true
    }
    if (loggedInArtist.CannotEdit) {
        return false
    }
    const recht = rechteMap && rechteMap[selectedArtistId]
    if (recht) {
        return hatRecht(recht, [RECHT_SCHREIBEN])
    }
    return false
}

export const convertImagesRaw2ProjektImages = images => images.map(t => ({type: 'projekt', ...t}))

export const convertProjName2ProjNameWithArtist = (artistMap, artistId, ProjName) => artistId && `${artistMap[artistId]?.Kuerzel}: ${ProjName}`

export const canCompressProjekte = (projektList, canEditProjektHandler) => {
    if (projektList?.length < 2) {
        return false
    }
    const kundenArtistCominations = projektList.reduce((pv, cv) => {
        const id = `${cv.KundID}#${cv.ArtistID}`
        if (!pv.includes(id)) {
            pv.push(id)
        }
        return pv
    }, [])
    if (kundenArtistCominations.length !== 1) {
        return false
    }
    return !(canEditProjektHandler && !canEditProjektHandler(projektList[0]));

}

export const getTranslatedProjektStatusMap = (t) => Object.keys(PROEJKTSTATI_MAP).reduce((pv, cv) => {
    pv[PROEJKTSTATI_MAP[cv]] = t(cv, {ns: i18_TRANSLATION})
    return pv
}, {})
